import React from "react"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { formatDate } from "../helpers/formatter"
import * as timelineItemStyles from "./timelineItem.module.scss"
import ClampLines from "react-clamp-lines"

const TimelineLink = (props) => {
  const { children, link } = props
  return (
    <Link to={`/timeline/${link}`}>
      {children}
    </Link>
  )
}

const TimelineItemText = function(props) {
  const { data, partnerEventImage } = props
  const isPartnerEvent = !!data.node.frontmatter.partnerEvent
  const path = data.node.fields.slug

  return (
    <div>
      <div className={`${timelineItemStyles.time}`}>
        <p><strong>{formatDate(data.node.frontmatter.date)}</strong></p>
        {isPartnerEvent && <p>&nbsp; &#183; &nbsp;</p>}
        {isPartnerEvent && partnerEventImage &&
          <div>
            <Img style={{ height: "20px", width: "150px", position: "absolute" }}
                 imgStyle={{ objectFit: "contain" }}
                 fixed={partnerEventImage.childImageSharp.fixed} alt="" />
          </div>
        }
      </div>
      <TimelineLink link={path}>
        <div className={`${timelineItemStyles.timelineItemContent} ${timelineItemStyles.contentBox}`}>
          {data.node.frontmatter.title && (
            <div className={timelineItemStyles.timelineItemTitle}>
              <ClampLines
                text={data.node.frontmatter.title}
                id={data.node.frontmatter.title}
                lines={2}
                ellipsis="..."
                innerElement="h2"
                className="pb-2"
              />
            </div>
          )}
          <div className={timelineItemStyles.timelineContent}>
            <div dangerouslySetInnerHTML={{ __html: data.node.html }}></div>
          </div>
        </div>
      </TimelineLink>
      <span className={timelineItemStyles.circle} />
    </div>
  )
}

const TimelineItemImage = function(props) {
  const { data, partnerEventImage } = props
  const isPartnerEvent = !!data.node.frontmatter.partnerEvent
  const path = data.node.fields.slug

  return (
    <div>
      <div className={`${timelineItemStyles.time}`}>
        <p><strong>{formatDate(data.node.frontmatter.date)}</strong></p>
        {isPartnerEvent && <div><p>&nbsp; &#183; &nbsp;</p></div>}
        {isPartnerEvent && partnerEventImage &&
          <div className={timelineItemStyles.partnerEventImageWrapper}>
            <Img style={{ height: "20px", width: "150px", position: "absolute", top: "-2px" }}
                 imgStyle={{ objectFit: "contain" }}
                 fixed={partnerEventImage.childImageSharp.fixed} alt="" />
          </div>
        }
      </div>
      <TimelineLink link={path}>
        <div className={timelineItemStyles.timelineItemContent}>
          <div className={timelineItemStyles.timelineImageItem}>
            <div className={timelineItemStyles.textAndImage}>
              <div className={timelineItemStyles.imageWrapper}>
                <Img
                  fixed={data.node.frontmatter.image.childImageSharp.fixed}
                  style={{ height: "100%", width: "100%" }} imgStyle={{ objectFit: "contain" }} backgroundColor={true}
                />
              </div>
              <div className={`${timelineItemStyles.text}`}>
                <div className={timelineItemStyles.contentBox}>
                  {data.node.frontmatter.title && (
                    <div className={timelineItemStyles.timelineItemTitle}>
                      <ClampLines
                        text={data.node.frontmatter.title}
                        id={data.node.frontmatter.title}
                        lines={2}
                        ellipsis="..."
                        innerElement="h2"
                        className="pb-2"
                      />
                    </div>
                  )}
                  <div className={timelineItemStyles.timelineContent}>
                    <div dangerouslySetInnerHTML={{ __html: data.node.html }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TimelineLink>
    </div>
  )
}

function TimelineItem(props) {
  const { data, index, partnerEventImage } = props
  let item
  if (data.node.frontmatter.image) {
    item = <div style={{ width: "100%" }}><TimelineItemImage data={data} index={index}
                                                             partnerEventImage={partnerEventImage} /></div>
  } else {
    item = <div style={{ width: "100%" }}><TimelineItemText data={data} index={index}
                                                            partnerEventImage={partnerEventImage} /></div>
  }

  return (
    <div className={`${timelineItemStyles.timelineItem}`}>
      {item}
    </div>
  )
}

export default TimelineItem
